<template>
  <div>
    <el-alert v-if="generalError" :title="generalError.title" :description="generalError.description" type="error" show-icon style="margin-bottom: 12px;" :closable="false"/>
    <div class="tab-pane active" id="tab1">
      <h2 class="signup-form-title">{{$t('signup.step-3.title')}}</h2>
    </div>
    <el-form-item :label="mailLabel" :error="formErrors && formErrors.email && formErrors.email[0]" @input="resetError('email')">
      <el-input v-model="edit.email" :placeholder="$t('signup.step-3.mail-user') + '@' + $t('signup.step-3.mail-domain')" class="full-width-input" required />
    </el-form-item>
    
    <!-- Groupe mot de passe et indicateur de fiabilité -->
    <div class="password-group">
      <el-form-item :label="$t('signup.step-3.password')" :error="formErrors && formErrors.plainPassword && formErrors.plainPassword[0]" @input="resetError('plainPassword')">
        <el-input v-model="edit.password" :placeholder="$t('signup.step-3.password-placeholder')" class="full-width-input" :type="passwordType" required>
          <template #suffix>
            <el-icon class="el-input__icon icon-link" @click="toogleViewPassword">
              <View v-if="!showPassword"/>
              <Hide v-else />
            </el-icon>
          </template>
        </el-input>
      </el-form-item>
      <p v-if="passwordSecureLevel" class="secure-text">{{$t('signup.step-3.password-secure.title')}} : <span :class="'password-secure ' + passwordSecureClass">{{ passwordSecureWord }}</span></p>
    </div>
    
    <!-- Widget Turnstile -->
    <div class="form-group turnstile-group">
      <div id="cf-turnstile" class="turnstile-container"></div>
      <p v-if="turnstileError" class="error-text">{{ turnstileError }}</p>
    </div>
    
    <el-checkbox v-model="edit.newsletter" :label="$t('signup.step-3.label-condition-2')" size="large" />
    <p class="condition-text">
      En créant mon compte j'accepte les <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">conditions générales</a>, <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">politique de confidentialité</a> et <a target="_blank" :href="path('lighty_share_lighty_share_conditions_generales')">notice d'assurance</a>.
    </p>
    <div class="form-group mt-4">
      <el-button class="signup-primary" :class="hasChanged ? '' : 'disabled'" @click="continu" :loading="isLoading">
        {{$t('signup.continue')}}
      </el-button>
    </div>
  </div>
</template>

<script>
import signupHandler from "../mixins/signupHandler.js"

export default {
  name: "RegisterLogin",
  mixins: [signupHandler],
  data () {
    return {
      showPassword: false,
      turnstileToken: null,
      turnstileError: null,
      turnstileWidget: null,
      edit: {
        email: '',
        password: '',
        plainPassword: '',
        newsletter: false
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setDefaultData()
      let allreadyLogged = vm.loggedUser
      if (allreadyLogged) {
        if (vm.nextStep) {
          vm.$router.push({name: vm.nextStep})
        }
      }
    })
  },
  mounted() {
    console.log('RegisterLogin component mounted');
    // Ajouter le script Turnstile si pas déjà présent
    if (!document.getElementById('turnstile-script')) {
      console.log('Adding Turnstile script to document');
      const script = document.createElement('script');
      script.id = 'turnstile-script';
      script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        console.log('Turnstile script loaded successfully');
        // Attendre un court instant pour s'assurer que le DOM est complètement chargé
        setTimeout(() => {
          this.initTurnstile();
        }, 100);
      };
      script.onerror = (error) => {
        console.error('Failed to load Turnstile script:', error);
      };
      document.head.appendChild(script);
    } else {
      console.log('Turnstile script already exists, initializing');
      setTimeout(() => {
        this.initTurnstile();
      }, 100);
    }
  },
  methods: {
    initTurnstile() {
      if (!window.turnstile) {
        console.error('Turnstile not loaded - window.turnstile is undefined');
        return;
      }

      // Vérification simple de l'élément DOM
      const container = document.getElementById('cf-turnstile');
      if (!container) {
        console.error("L'élément DOM 'cf-turnstile' n'existe pas");
        return;
      }

      // Get site key from global config
      const siteKey = window.TURNSTILE_SITE_KEY;
      console.log('Initializing Turnstile with site key:', siteKey ? (siteKey.substring(0, 10) + '...') : 'MISSING');
      
      if (!siteKey) {
        console.error('Turnstile site key not found');
        this.turnstileError = 'Configuration error: Turnstile not properly configured.';
        return;
      }
      
      // Initialisation simple du widget
      try {
        // Nettoyage de l'élément
        container.innerHTML = '';
        
        this.turnstileWidget = window.turnstile.render('#cf-turnstile', {
          sitekey: siteKey,
          callback: (token) => {
            console.log('Turnstile token received');
            this.turnstileToken = token;
            this.turnstileError = null;
          },
          'expired-callback': () => {
            console.log('Turnstile token expired');
            this.turnstileToken = null;
            this.turnstileError = 'La vérification a expiré, veuillez réessayer.';
          },
          'error-callback': () => {
            console.error('Turnstile error occurred');
            this.turnstileToken = null;
            this.turnstileError = 'Une erreur est survenue lors de la vérification.';
          }
        });
        console.log('Turnstile widget rendered with ID:', this.turnstileWidget);
      } catch (e) {
        console.error('Error initializing Turnstile:', e);
        this.turnstileError = 'Erreur d\'initialisation de la vérification de sécurité.';
      }
    },
    getPasswordSecureLevel(password) {
      const $badRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{0,1}$/
      const $goodRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
      const $betterRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
      const $bestRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{9,}$/
      
      // Implémentation alternative qui détecte aussi les caractères spéciaux
      const hasLower = /[a-z]/.test(password);
      const hasUpper = /[A-Z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
      const complexity = (hasLower ? 1 : 0) + (hasUpper ? 1 : 0) + (hasNumber ? 1 : 0) + (hasSpecial ? 1 : 0);
      
      // Test d'abord avec les regex standards
      if ($bestRegex.test(password)) {
        return 'strong';
      } else if ($betterRegex.test(password)) {
        return 'medium';
      } else if ($goodRegex.test(password)) {
        return 'weak';
      } 
      
      // Si aucun match avec les regex standard, utiliser l'approche par complexité
      if (password.length >= 8 && complexity >= 3) {
        return 'strong';
      } else if (password.length >= 6 && complexity >= 2) {
        return 'medium';
      } else if (password.length >= 6 && complexity >= 1) {
        return 'weak';
      } else {
        if (password.length < 2) {
          return false;
        }
        return 'very-weak';
      }
    },
    resetTurnstile() {
      if (window.turnstile && this.turnstileWidget) {
        window.turnstile.reset(this.turnstileWidget);
      }
    },
    resetError(field) {
      if (this.formErrors && this.formErrors[field]) {
        delete this.formErrors[field]
      }
    },
    async beforeContinu() {
      if (!this.turnstileToken) {
        this.turnstileError = 'Veuillez compléter la vérification de sécurité.';
        return false;
      }
      
      // S'assurer que statutUser est défini avant de continuer
      if (!this.signupUser.statutUser) {
        // Définir une valeur par défaut et mettre à jour l'état global
        this.UpdateSignupUser({
          ...this.signupUser,
          statutUser: 1
        });
      }
      
      // Création du FormData
      const formData = new FormData();
      
      // Ajout des données du formulaire
      if (this.signupUser) {
        // Ajout des données de base
        formData.append('fos_user_registration_form[email]', this.edit.email);
        
        // CORRECTION: Utiliser plainPassword au lieu de password (format attendu par FOSUserBundle)
        formData.append('fos_user_registration_form[plainPassword]', this.edit.password);
        formData.append('fos_user_registration_form[newsletter]', this.edit.newsletter);
        
        // CORRECTION: Ajouter username obligatoirement (requis par FOSUserBundle)
        // Utiliser l'email comme username par défaut si non défini
        if (this.signupUser.username) {
          formData.append('fos_user_registration_form[username]', this.signupUser.username);
        } else {
          formData.append('fos_user_registration_form[username]', this.edit.email);
        }
        
        // Statut utilisateur (toujours défini maintenant grâce au code ci-dessus)
        formData.append('fos_user_registration_form[statutUser]', this.signupUser.statutUser || 1);
        
        // Autres champs nécessaires
        if (this.signupUser.prenom) {
          formData.append('fos_user_registration_form[prenom]', this.signupUser.prenom);
        }
        
        if (this.signupUser.nom) {
          formData.append('fos_user_registration_form[nom]', this.signupUser.nom);
        }
        
        if (this.signupUser.dateNaissance) {
          formData.append('fos_user_registration_form[dateNaissance]', 
                          this.signupUser.dateNaissance.replaceAll('/', '-'));
        }
        
        // Pour les autres champs éventuels
        formData.append('fos_user_registration_form[manually]', 'true');
        
        // Ajout du token CSRF et du token Turnstile
        if (this._token) {
          formData.append('fos_user_registration_form[_token]', this._token);
          formData.append('_token', this._token);
        }
        
        formData.append('cf-turnstile-response', this.turnstileToken);
      }
      
      // Envoi du formulaire
      this.isLoading = true;
      try {
        // Log pour déboguer ce qui est envoyé
        console.log('Envoi du formulaire avec les champs:', {
          email: this.edit.email,
          username: this.signupUser?.username || this.edit.email,
          plainPassword: this.edit.password ? '[PRÉSENT]' : '[VIDE]',
          turnstileToken: this.turnstileToken ? '[PRÉSENT]' : '[VIDE]',
          statutUser: this.signupUser?.statutUser || 1
        });
        
        const response = await fetch(this.submitUrl, {
          method: 'POST',
          body: formData
        }).then(res => res.json());
        
        this.isLoading = false;
        console.log('Réponse du serveur complète:', response);
        
        if (response) {
          if (response.form_errors) {
            console.error('Erreurs de formulaire:', response.form_errors);
            this.formErrors = response.form_errors;
          }
          
          if (response.token_csrf) {
            this._token = response.token_csrf;
          }
          
          if (response.turnstile_error) {
            this.turnstileError = response.turnstile_error;
            this.resetTurnstile();
            return false;
          }
        }
        
        return !this.formErrors || (!this.formErrors.email && !this.formErrors.plainPassword);
      } catch (e) {
        console.error('Erreur lors de la soumission du formulaire:', e);
        this.isLoading = false;
        return false;
      }
    },
    setDefaultData() {
      if (this.signupUser) {
        this.edit.email = this.signupUser.email || '';
        this.edit.password = this.signupUser.password || '';
        this.edit.newsletter = this.signupUser.newsletter || false;
      }
    },
    toogleViewPassword() {
      this.showPassword = !this.showPassword;
    }
  },
  watch: {
    edit: {
      handler: function(val) {
        if (val) {
          // Mettre à jour l'objet signupUser pour inclure username et statutUser
          this.UpdateSignupUser({
            id: 1,
            email: val.email,
            password: val.password,
            newsletter: val.newsletter,
            username: val.email, // Utiliser l'email comme username par défaut
            statutUser: this.signupUser?.statutUser || 1 // S'assurer que statutUser est toujours défini
          });
        }
      },
      deep: true
    }
  },
  computed: {
    mailLabel() {
      if (this.signupUser && this.signupUser.statutUser === 1) {
        return this.$t('signup.step-3.mail');
      } else {
        return this.$t('signup.step-3.mail-pro');
      }
    },
    submitUrl() {
      return '/api/check-signup';
    },
    passwordSecureLevel() {
      return this.getPasswordSecureLevel(this.edit.password);
    },
    passwordSecureClass() {
      if (this.passwordSecureLevel) {
        return 'password-secure-' + this.passwordSecureLevel;
      }
      return '';
    },
    passwordSecureWord() {
      if (this.passwordSecureLevel) {
        return this.$t('signup.step-3.password-secure.' + this.passwordSecureLevel);
      }
      return '';
    },
    passwordType() {
      return this.showPassword ? 'text' : 'password';
    },
    hasChanged() {
      let mailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      let passwordRegex = /(?=.{8,}).*/;
      return mailRegex.test(this.edit.email) && 
             passwordRegex.test(this.edit.password) &&
             this.turnstileToken !== null;
    }
  }
}
</script>

<style scoped>
.password-secure {
  font-weight: bold;
}

.password-secure-very-weak {
  color: #FF0000; /* Red */
}

.password-secure-weak {
  color: #FFA500; /* Orange */
}

.password-secure-medium {
  color: #FFD700; /* Gold (More visible than Yellow) */
}

.password-secure-strong {
  color: #008000; /* Green */
}

/* Styles Turnstile */
.turnstile-container {
  margin: 15px 0;
  display: flex;
  justify-content: flex-start;
}

.error-text {
  color: #f56c6c;
  font-size: 12px;
  margin-top: 5px;
}

/* Nouveau groupe de mot de passe */
.password-group {
  margin-bottom: 20px;
}

.password-group .secure-text {
  margin-top: 8px;
  margin-bottom: 0;
}

.turnstile-group {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>